<template>
  <div>

    <van-form :disabled="!(status == 0 || status == 2 || status == 4)" @submit="onSubmitStep" @failed="onFailedStep">
      
      <van-row v-if="langType == 1">
        <van-col span="24">
          <van-field label="是否参加《国际中文教师证书》的专场考试"   placeholder="请填写" >
            <template #input>
              <van-radio-group v-model="form.chinese_certificate_status" direction="horizontal">
                <van-radio name="1" shape="square">是</van-radio>
                <van-radio name="2" shape="square">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </van-col>
      </van-row>

      <van-row>
        <van-col span="12">
          <van-field required :label="label.realname[langType]" v-model="form.realname" name="realname" placeholder="请填写"
            :rules="[{ required: true, message: '' }]" />
        </van-col>
        <van-col span="12">
          <van-field required :label="label.nationality[langType]" v-model="form.nationality" name="nationality"
            placeholder="请填写" :rules="[{ required: true, message: '' }]" />
        </van-col>
      </van-row>

      <van-row>
        <van-col span="12">
          <van-field :label="label.nationality_old[langType]" v-model="form.nationality_old" name="nationality_old"
            placeholder="请填写" />
        </van-col>
        <van-col span="12">
          <van-field required :label="label.birthdate[langType]" v-model="form.birthdate" name="birthdate"
            placeholder="请填写" :formatter="formatDate" format-trigger="onBlur"
            :rules="[{ required: true, message: '' }, { validator: validatorBirthdate, message: label.birthdateTips[langType] }]" />
        </van-col>
      </van-row>

      <van-row>
        <van-col span="12">
          <van-field required :label="label.age[langType]" v-model="form.age" name="age" placeholder="请填写"
            :rules="[{ required: true, message: '' }]" />
        </van-col>
        <van-col span="12">
          <van-field required :label="label.marriage[langType]" v-model="form.marriage" name="marriage" placeholder="请填写"
            :rules="[{ required: true, message: '' }]" />
        </van-col>
      </van-row>

      <van-row>
        <van-col span="12">
          <van-field :label="label.family[langType]" v-model="form.family" name="family" placeholder="请填写" />
        </van-col>

        <van-col span="12">
          <van-field required :label="label.gender[langType]" v-model="form.gender" name="gender" placeholder="请选择"
            :rules="[{ required: true, message: '' }]" >
            <template #input>
              <div>
                <el-select v-show="langType == 1" v-model="form.gender"  placeholder="请选择">
                  <el-option  label="男" value="男">
                  </el-option>
                  <el-option  label="女" value="女">
                  </el-option>
                </el-select>

                <el-select v-show="langType == 2" v-model="form.gender" placeholder="请选择">
                  <el-option  label="male" value="男">
                  </el-option>
                  <el-option  label="female" value="女">
                  </el-option>
                </el-select>
              </div>
            </template>
          </van-field>

        </van-col>
        
      </van-row>

      <van-row>
        
        <van-col span="12">
          <van-field required :label="label.nation[langType]" v-model="form.nation" name="nation" placeholder="请填写" :rules="[{ required: true, message: '' }]"
             />
        </van-col>

        <van-col span="12">
          <van-field required :label="label.job_status[langType]" v-model="form.job_status" name="job_status"
            placeholder="请填写" :rules="[{ required: true, message: '' }]" />
        </van-col>
      </van-row>

      <van-row>
        <van-col span="12">
          <van-field :label="label.job_address[langType]" v-model="form.job_address" name="job_address"
            placeholder="请填写" />
        </van-col>
        <van-col span="12">
          <van-field :label="label.job_country[langType]" v-model="form.job_country" name="job_country"
            placeholder="请填写" />
        </van-col>
      </van-row>

      <van-row>
        <van-col span="12">
          <van-field required :label="label.mobile[langType]" v-model="form.mobile" name="mobile" placeholder="请填写"
            :rules="[
              { required: true, message: '' }
            ]" />
        </van-col>
        <van-col span="12">
          <van-field required :label="label.email[langType]" v-model="form.email" name="email" placeholder="请填写" :rules="[
            { required: true, message: '' }
          ]" />
        </van-col>

      </van-row>

      <van-row>
        <van-col span="12">
          <van-field required :label="label.address_now[langType]" v-model="form.address_now" name="address_now"
            placeholder="请填写" :rules="[
              { required: true, message: '' }
            ]" />
        </van-col>
        <van-col span="12">
          <van-field :label="label.address_uea[langType]" v-model="form.address_uea" name="address_uea"
            placeholder="请填写" />
        </van-col>
        <van-col span="24">
          <van-field  v-if="langType == 1" required  :label="label.address_country[langType]"  v-model="form.address_province" name="address_province" placeholder="请填写"
            :rules="[{ required: true, message: '' }]" >
            <template #input>
              <div>
                <el-select  v-model="form.address_province" filterable placeholder="请选择省份">
                  <el-option v-for="(item, j) in provinceList" :key="j" :label="item.area_name"
                    :value="item.area_name">
                  </el-option>
                </el-select>
              </div>
              <div>
                <van-field label=""  v-model="form.address_country"
                  name="address_country" placeholder="请填写" :rules="[
                    { required: true, message: '' }
                  ]" />
              </div>
            </template>
          </van-field>

          <van-field  v-if="langType == 2" required :label="label.address_country[langType]" v-model="form.address_country"
            name="address_country" placeholder="请填写" :rules="[
              { required: true, message: '' }
            ]" />
        </van-col>
      </van-row>

      <!-- <van-field required name="avatarUploader" :label="label.avatar[langType]">
        <template #input>
          <van-uploader :disabled="!(status == 0 || status == 2 || status == 4)" class="photo" :preview-full-image="false" v-model="avatarUploader" :max-count="1"
            :after-read="afterReadAvatar" :accept="isMobile ? '*' : '.jpg,.jpeg,.png'" @delete="deleteAlone('avatar')" />
        </template>
      </van-field> -->


      <!-- 教育经历 -->
      <div class="experience-model-card">
        <div class="experience-model-header">
          <p>{{ label.form_edu[langType] }}</p>

        </div>

        <div class="experience-table edu-experience-table">
          <van-row type="flex" align="center" class="header">
            <van-col span="6">{{ label.certificate1[langType] }} <span>{{ label.certificate2[langType] }}</span></van-col>
            <van-col span="5">{{ label.org_title[langType] }}</van-col>
            <van-col span="3">{{ label.course_time[langType] }}</van-col>
            <van-col span="5">{{ label.graduate_time[langType] }}</van-col>
            <van-col span="3">{{ label.major[langType] }}</van-col>
            <van-col span="2"></van-col>
          </van-row>

          <van-row v-for="(edu, i) in form.job_sign_form_edu" :key="i" class="list" type="flex" align="center">
            <van-col span="6">
              <van-field label="" v-model="edu.certificate" name="certificate" placeholder="请填写"
                :rules="[{ required: true, message: '' }]" >
                <template #input>
                  <div>
                    <el-select v-show="langType == 1" v-model="edu.certificate" filterable placeholder="请选择">
                      <el-option v-for="(item, j) in form.default_edu_cn_options" :key="j" :label="item"
                        :value="item">
                      </el-option>
                    </el-select>

                    <el-select v-show="langType == 2" v-model="edu.certificate" filterable placeholder="请选择">
                      <el-option v-for="(item, j) in form.default_edu_en_options" :key="j" :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                  </div>
                </template>
              </van-field>

                

            </van-col>
            <van-col span="5">
              <van-field label="" v-model="edu.org_title" name="org_title" placeholder="请填写"
                :rules="[{ required: true, message: '' }]" />
            </van-col>
            <van-col span="3">
              <van-field label="" v-model="edu.course_time" name="course_time" placeholder="请填写"
                :rules="[{ required: true, message: '' }]" />
            </van-col>
            <van-col span="5">
              <van-field label="" v-model="edu.graduate_time" name="graduate_time" placeholder="请填写"
                :formatter="formatDate" format-trigger="onBlur"
                :rules="[{ required: true, message: '' }, { validator: validatorBirthdate, message: label.dateTips[langType] }]" />
            </van-col>
            <van-col span="3">
              <van-field label="" v-model="edu.major" name="major" placeholder="请填写"
                :rules="[{ required: true, message: '' }]" />
            </van-col>
            <van-col span="2">
              <van-button v-if="i != 0" :disabled="!(status == 0 || status == 2 || status == 4)" native-type='button'
                @click.stop="handleDelEdu(i)" size="small">删除</van-button>
            </van-col>
          </van-row>
        </div>

        <div style="text-align: left;margin: 10px 0;">
          <van-button :disabled="!(status == 0 || status == 2 || status == 4)" @click.stop="handleAddEdu" native-type='button'
            size="small">添加</van-button>
        </div>

      </div>

      <!-- 非英语母语人士 -->
      <div class="experience-model-card">
        

        <div class="experience-table edu-experience-table">
          <van-row type="flex" align="center" class="header">
            <van-col span="6">{{ label.english_native[langType] }} </van-col>
            <van-col span="18">
              <p style="margin:10px;text-align: left;">
                <el-select v-model="form.english_native" filterable placeholder="请选择" :rules="[{ required: true, message: '' }]">
                  <el-option  :label="langType == 1 ? '是' : 'Yes'"
                    :value="langType == 1 ? '是' : 'Yes'">
                  </el-option>
                  <el-option  :label="langType == 1 ? '否' : 'No'"
                    :value="langType == 1 ? '否' : 'No'">
                  </el-option>
                </el-select> 
              </p>
            </van-col>
          </van-row>
          <van-row type="flex" align="center" class="list">
            <van-col span="6"> <p>{{ label.english_native_level[langType] }}</p> </van-col>
            <van-col span="5">{{ label.english_complate[langType] }}</van-col>
            <van-col span="4">{{ label.english_desc[langType] }}</van-col>
            <van-col span="5">{{ label.english_type[langType] }}</van-col>
            <van-col span="4">{{ label.english_year[langType] }}</van-col>
          </van-row>
          <van-row type="flex" align="center" class="list">
            <van-col span="6"><p>{{ label.english_ielts[langType] }}</p> </van-col>
            <van-col span="5">
              <p style="margin:10px">
                <el-select v-model="form.english_ielts_status" filterable placeholder="请选择">
                  <el-option  :label="langType == 1 ? '是' : 'Yes'"
                    :value="langType == 1 ? '是' : 'Yes'">
                  </el-option>
                  <el-option  :label="langType == 1 ? '否' : 'No'"
                    :value="langType == 1 ? '否' : 'No'">
                  </el-option>
                </el-select> 
              </p>
            </van-col>
            <van-col span="4">
              <van-field class="van-field-self"  label="" v-model="form.english_ielts_score" name="english_ielts_score"
              placeholder="请填写" />
            </van-col>
            <van-col span="5">
              <p style="margin:10px">
                <el-select v-model="form.english_ielts_type" filterable placeholder="请选择">
                  <el-option  :label="label.english_academics[langType]"
                    :value="label.english_academics[langType]">
                  </el-option>
                  <el-option  :label="label.english_general_training[langType]"
                    :value="label.english_general_training[langType]">
                  </el-option>
                </el-select> 
              </p>
            </van-col>
            <van-col span="4">
              <van-field class="van-field-self" label="" v-model="form.english_ielts_year" name="english_ielts_year"
              :formatter="formatDate" format-trigger="onBlur" placeholder="请填写"
              :rules="[{ validator: validatorEnglishdate, message: label.dateTips[langType] }]" />
            </van-col>
          </van-row>
          <van-row type="flex" align="center" class="list">
            <van-col span="6"><p>{{ label.english_toefl[langType] }} </p></van-col>
            <van-col span="5">
              <p style="margin:10px">
                <el-select v-model="form.english_toefl_status" filterable placeholder="请选择">
                  <el-option  :label="langType == 1 ? '是' : 'Yes'"
                    :value="langType == 1 ? '是' : 'Yes'">
                  </el-option>
                  <el-option  :label="langType == 1 ? '否' : 'No'"
                    :value="langType == 1 ? '否' : 'No'">
                  </el-option>
                </el-select> 
              </p>
            </van-col>
            <van-col span="4">
              <van-field class="van-field-self"  label="" v-model="form.english_toefl_score" name="english_toefl_score"
              placeholder="请填写" />
            </van-col>
            <van-col span="5"></van-col>
            <van-col span="4">
              <van-field class="van-field-self" label="" v-model="form.english_toefl_year" name="english_toefl_year"
              :formatter="formatDate" format-trigger="onBlur" placeholder="请填写"
              :rules="[{ validator: validatorEnglishdate, message: label.dateTips[langType] }]" />
            </van-col>
          </van-row>

        </div>

        

      </div>
      
   

      <!-- 工作经历 -->
      <div class="experience-model-card">
        <div class="experience-model-header">
          <p>{{ label.form_work[langType] }}</p>
        </div>

        <div class="experience-table edu-experience-table">
          <van-row type="flex" align="center" class="header">
            <van-col span="3">{{ label.form_work_no[langType] }}</van-col>
            <van-col span="5">{{ label.form_work_country[langType] }}</van-col>
            <van-col span="7">
              <van-col span="24"> {{ label.form_work_teach[langType] }}</van-col>
              <van-col span="12"> {{ label.form_work_unit[langType] }}</van-col>
              <van-col span="12"> {{ label.form_work_year[langType] }}</van-col>
            </van-col>
            <van-col span="7">
              <van-col span="24"> {{ label.form_work_admin[langType] }}</van-col>
              <van-col span="12"> {{ label.form_work_unit[langType] }}</van-col>
              <van-col span="12"> {{ label.form_work_year[langType] }}</van-col>
            </van-col>
            <van-col span="2"></van-col>
          </van-row>

          <van-row v-for="(edu, i) in form.job_sign_form_experience" :key="i" class="list" type="flex" align="center">
            <van-col span="3">
              <p>{{ i + 1 }}</p>
            </van-col>
            <van-col span="5">

              <van-field style="height:0px !important; padding: 0 !important;" v-model="edu.country" label=""
                name="country" placeholder="请填写" :rules="[{ required: true, message: '' }]">
              </van-field>

              <van-field class="van-field-self" label="" name="country" placeholder="请填写">
                <template #input>

                
                  <input style="background: transparent;border: none;" disabled v-show="i < form.default_country_cn_list.length" placeholder="请输入"
                    v-model="edu.country" /> 
                  

                  <div v-show="i >= form.default_country_cn_list.length">
                    <el-select v-show="langType == 1" v-model="edu.country" filterable placeholder="请选择">
                      <el-option v-for="(item, j) in form.default_country_options" :key="j" :label="item.title"
                        :value="item.title">
                      </el-option>
                    </el-select>

                    <el-select v-show="langType == 2" v-model="edu.country" filterable placeholder="请选择">
                      <el-option v-for="(item, j) in form.default_country_options" :key="j" :label="item.title_en"
                        :value="item.title_en">
                      </el-option>
                    </el-select>
                  </div>

                </template>

              </van-field>

             
            </van-col>
            <van-col span="7">
              <van-col span="12">
                <van-field label="" v-model="edu.teaching_company" name="teaching_company" placeholder="请填写"
                 />
              </van-col>
              <van-col span="12">  
                <van-field label="" v-model="edu.teaching_year" name="teaching_year" placeholder="请填写"
                 />
              </van-col>
            </van-col>
            <van-col span="7">
              <van-col span="12">  
                <van-field label="" v-model="edu.administrative_company" name="administrative_company" placeholder="请填写"
                />
              </van-col>
              <van-col span="12">  
                <van-field label="" v-model="edu.administrative_year" name="administrative_year" placeholder="请填写"
                 />
              </van-col>
            </van-col>
            <van-col span="2">
              <van-button v-if="i > form.default_country_cn_list.length - 1" :disabled="!(status == 0 || status == 2 || status == 4)"
                native-type='button' @click.stop="handleDelJob(i,edu)" size="small">删除</van-button>
            </van-col>
          </van-row>
        </div>

        <div style="text-align: left;margin: 10px 0;">
          <van-button :disabled="!(status == 0 || status == 2 || status == 4)" @click.stop="handleAddJob" native-type='button'
            size="small">添加</van-button>
        </div>

      </div>

      <!-- 个人简历 -->
      <div class="experience-model-card">
        <div class="experience-model-header">
          <p>{{ label.introduce[langType] }} </p>
        </div>
        <div class="experience-table">
          <van-row type="flex" align="center" class="header">
            <van-col span="24">
              <van-field v-model="form.introduce" name="introduce" rows="4" autosize type="textarea"
                :placeholder="label.introduce[langType]" :rules="[{ required: true, message: '' }]" />
            </van-col>
          </van-row>
        </div>
      </div>

      <div class="submit-content">
        <van-button v-if="active === 1" class="submit-btn" block type="info" native-type='button'
          @click.stop="handlePreStep"><span>上一步</span></van-button>
          <!-- :disabled="fromDisabled" -->
        <van-button class="submit-btn" block type="info" native-type="submit"><span>下一步</span></van-button>
      </div>
    </van-form>

  </div>
</template>

<script>
import { Toast } from "vant";
import * as qiniu from 'qiniu-js'

export default {
  name: "step",
  props: {
    langType: {
      default: 1
    },
    active: {}
  },
  data() {
    return {
      fromDisabled:false,
      provinceList:[],
      label: {
        realname: {
          1: '姓名（（按护照）',
          2: 'Name(As per passport)'
        },
        nationality: {
          1: '国籍（现在）',
          2: 'Nationality(Present)'
        },
        nationality_old: {
          1: '国籍（之前的，如有）',
          2: 'Nationality(Previous, if any)'
        },
        birthdate: {
          1: '出生日期（yyyy/mm/dd）',
          2: 'Date of Birth（yyyy/mm/dd）'
        },
        birthdateTips: {
          1: '请输入正确的出生日期（yyyy/mm/dd）',
          2: 'Please enter the correct Date of Birth（yyyy/mm/dd）'
        },
        dateTips: {
          1: '请输入正确的日期（yyyy/mm/dd）',
          2: 'Please enter the correct Date（yyyy/mm/dd）'
        },
        age: {
          1: '年龄',
          2: 'Age'
        },
        marriage: {
          1: '婚姻（已婚、未婚、离异）',
          2: 'Marital Status（Married ，Single，Divorced）'
        },
        family: {
          1: '子女（数量）',
          2: 'Dependants（Number）'
        },
        gender:{
          1: '性别',
          2: 'Gender'
        },
        nation:{
          1: '民族',
          2: 'Nationality'
        },
        job_status: {
          1: '目前是否在职？',
          2: 'Currently, are you employed ?'
        },
        job_address: {
          1: '单位名称（如果在职）',
          2: 'if employed, Name of Employer'
        },
        job_country: {
          1: '工作国家（如果在职）',
          2: 'if employed, Country of Work'
        },
        mobile: {
          1: '手机号码',
          2: 'Contact Mobile No'
        },
        email: {
          1: 'Email',
          2: 'Contact Email ID'
        },
        address_now: {
          1: '现住址',
          2: 'Current Location'
        },
        address_uea: {
          1: '国外地址',
          2: 'Foreign address'
        },
        address_country: {
          1: '本国地址（户籍地址）',
          2: 'Address in Home Country（Permanent Residence Address）'
        },
        avatar: {
          1: '申请人照片（必须提供）',
          2: 'Candidate Picture (compulsory)'
        },
        form_edu: {
          1: '教育经历',
          2: 'Educational Qualifications'
        },
        certificate1: {
          1: '申请者提供如下证书',
          2: 'Candidate has the following Cert/s'
        },
        certificate2: {
          1: '（学位/相关证书按降序排列）',
          2: '(Degree / Diploma etc in descending order)'
        },
        org_title: {
          1: '学院/大学/机构名称和地点',
          2: 'Name & Place of College / University / Insti-tution'
        },
        course_time: {
          1: '课程时限',
          2: 'Course Dura-tion'
        },
        graduate_time: {
          1: '毕业时间',
          2: 'Completion Year'
        },
        major: {
          1: '专业',
          2: 'Major Sub-jects'
        },
        english_native: {
          1: '非英语母语人士',
          2: 'For Non-Native English Speakers'
        },
        english_native_level:{
          1: '语言水平',
          2: 'Language Proficiency Test'
        },
        english_complate: {
          1: '是否已完成考试？',
          2: 'Complete the Test or not?'
        },
        english_desc: {
          1: '分数',
          2: 'Score'
        },
        english_type:{
          1: '类型',
          2: 'type'
        },
        english_ielts:{
          1: '雅思',
          2: 'IELTS'
        },
        english_toefl:{
          1: '托福',
          2: 'TOEFL'
        },
        english_academics: {
          1: '学术类',
          2: 'Academic'
        },
        english_general_training: {
          1: '培训类',
          2: 'General'
        },
        english_year: {
          1: '完成日期',
          2: 'Completion Date'
        },
        form_work: {
          1: '工作经历',
          2: 'Experiences'
        },

        form_work_uea: {
          1: '阿联酋',
          2: 'UAE'
        },
        form_work_foreign: {
          1: '国外（除阿联酋）',
          2: 'Abroad(except UAE)'
        },
        form_work_all: {
          1: '总年限',
          2: 'Total No. of Years'
        },

        form_work_country: {
          1: '国家',
          2: 'country'
        },
        form_work_no: {
          1: '序号',
          2: 'No'
        },
        form_work_teach: {
          1: '和申请职位相关的教学经历的工作年限',
          2: 'Years of Teaching Experience - (related to the position applied for)'
        },
        form_work_admin: {
          1: '非教学经历/行政的工作年限',
          2: 'Years of Non-Teaching / Admin  Experience'
        },
        form_work_unit:{
          1: '单位名称',
          2: 'Company Name'
        },
        form_work_year:{
          1: '工作年限',
          2: 'Years of work experience'
        },
        introduce: {
          1: '自我介绍',
          2: 'Write a brief about yourself'
        }
      },
      form: {
        chinese_certificate_status:null,
        realname: '',
        mobile: '',
        avatar: '',
        nationality: '',
        nationality_old: '',
        birthdate: '',
        marriage: '',
        age: '',
        family: '',
        gender:'',
        nation:'',
        job_status: '',
        job_address: '',
        job_country: '',
        email: '',
        address_now: '',
        address_uea: '',
        address_province:'2',
        address_country: '',
        introduce: '',
        job_sign_form_edu: [
          {
            certificate: '',
            org_title: '',
            course_time: '',
            graduate_time: '',
            major: ''
          }
        ],
        job_sign_form_experience: [],
        english_native: '',
        english_ielts: '',
        english_desc: '',
        english_academics: '',
        english_year: '',
        english_ielts_status:'',
        english_ielts_type:'',
        english_ielts_score:'',
        english_ielts_year:'',
        english_toefl_status:'',
        english_toefl_score:'',
        english_toefl_year:''
      },
      status: 0,
      avatarUploader: []
    };
  },
  created() { },
  mounted() {
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 992;
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.job_id) {
          this.getProvince()
          this.getSign()
        }

      }
    },
    'active': {
      deep: true,
      handler() {
        this.getProvince()
        this.getSign()
      }
    },
  },
  methods: {

    formatDate(str) {
      if(str){
        return str
        .split("/")
        .map((item) => {
          if (+item < 10) {
            return "0" + +item;
          }
          return item;
        })
        .join("/");
      }else{
        return ''
      }
      
    },
    validatorBirthdate(val) {
      return /\d{4}[/](0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])/.test(val);
    },
    validatorEnglishdate(val) {
      if (val) {
        return /\d{4}[/](0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])/.test(val);
      } else {
        return true
      }
    },
    // 添加教育经历
    handleAddEdu() {
      this.form.job_sign_form_edu.push({
        certificate: '',
        org_title: '',
        course_time: '',
        graduate_time: '',
        major: ''
      })
    },
    handleDelEdu(i) {
      this.form.job_sign_form_edu.splice(i, 1)
      // this.onSubmit('del')

    },
    // 添加工作经历
    handleAddJob() {
      this.form.job_sign_form_experience.push({
        country: '',
        teaching_company:'',
        teaching_year: '',
        administrative_company:'',
        administrative_year: ''
      })
    },
    handleDelJob(i,item) {
      this.form.job_sign_form_experience.splice(i, 1)
      console.log(item)
      this.onSubmit('del')
    },
    deleteAlone(type) {
      this.form[type] = ''
      this.form[`${type}_title`] = ''
    },
    getProvince(){
      this.$api.GET_LEVEL1_LIST().then(
          (res) => {
            console.log(res)
            this.provinceList = res
          },
          () => {
            
          }
        );
    },
    getSign() {
      this.$api.SIGN_GET1({ lang_type: this.langType, job_id: this.$route.query.job_id }).then(res => {
        if (res) {

          let default_job_sign_form_cn_experience = res.default_country_cn_list.map(item => {
            return {
              country: item,
              teaching_company:'',
              teaching_year: '',
              administrative_company:'',
              administrative_year: ''
            }
          })

          let default_job_sign_form_en_experience = res.default_country_en_list.map(item => {
            return {
              country: item,
              teaching_company:'',
              teaching_year: '',
              administrative_company:'',
              administrative_year: ''
            }
          })

          let default_job_sign_form_experience = this.langType == 1 ? default_job_sign_form_cn_experience : default_job_sign_form_en_experience


          if (res.id) {
            this.form = {
              ...res,
              job_sign_form_edu: res.job_sign_form_edu ? res.job_sign_form_edu : [{
                certificate: '',
                org_title: '',
                course_time: '',
                graduate_time: '',
                major: ''
              }],

              job_sign_form_experience: res.job_sign_form_experience.length > 0 ? res.job_sign_form_experience : default_job_sign_form_experience

            }




            this.status = res.status

            if (res.avatar_url) {
              this.avatarUploader = [{
                file: {
                  name: res.avatar
                },
                url: res.avatar_url
              }]
            }
          }else{
            this.form.default_country_cn_list = res.default_country_cn_list;
            this.form.default_country_en_list = res.default_country_en_list;
            this.form.default_country_options = res.default_country_options;
            this.form.default_edu_cn_options = res.default_edu_cn_options;
            this.form.default_edu_en_options = res.default_edu_en_options;

            this.form.job_sign_form_edu = [{
              certificate: '',
              org_title: '',
              course_time: '',
              graduate_time: '',
              major: ''
            }]
            this.form.job_sign_form_experience = default_job_sign_form_experience
          }

        }
      })
    },
    handlePreStep() {
      if (this.active === 1) {
        this.$emit('updateActive', 0)
      }
    },
    onFailedStep() {
      Toast("请完善必填项");
    },
    onSubmitStep() {
      // if (!this.form.avatar) {
      //   Toast("请上传照片");
      //   return
      // }
      this.onSubmit()
    },
    onSubmit(type) {

      const param = {
        ...this.form,
        job_id: this.$route.query.job_id,
        lang_type: this.langType
      };

      if (this.status == 0 || this.status == 4) {
        this.fromDisabled = true
        this.$api.JOB_SIGN_STEP1(param).then(
          () => {
            this.fromDisabled = false
            if (type === 'del') {
              return
            }
            if (this.active === 0) {
              this.$emit('updateActive', 1)
            }
            if (this.active === 1) {
              this.$emit('updateActive', 2)
            }
          },
          () => {
            this.fromDisabled = false
          }
        );
      } else {
        if (this.active === 0) {
          this.$emit('updateActive', 1)
        }
        if (this.active === 1) {
          this.$emit('updateActive', 2)
        }
      }
    },
    afterReadAvatar(file) {
      this.afterRead(file, 'avatar')
    },
    getUuidCode() {
      var charactors = "0123456789";
      var value = '', i;
      for (let j = 1; j <= 4; j++) {
        i = parseInt(10 * Math.random());
        value = value + charactors.charAt(i);
      }
      return value
    },
    // 上传图片
    async afterRead(file, type) {
      let _this = this
      let filename = file.file.name
      file.status = 'uploading';
      file.message = '上传中...';
      const tokenRes = await this.$api.QINIU_TOKEN();
      const nameKey = new Date().getTime() + this.getUuidCode() + filename.substr(filename.lastIndexOf("."))
      const config = {}
      const putExtra = {
        fname: filename
      }
      var observable = qiniu.upload(
        file.file,
        nameKey,
        tokenRes.uptoken,
        config,
        putExtra
      )
      var observer = {
        //   接收上传进度信息
        next() {
        },
        // 接收上传错误信息
        error() {
          file.status = 'failed';
          file.message = '上传失败';
          _this[`${type}Uploader`] = []

        },
        // 接收上传完成后的信息
        complete(res) {
          // https://qiniu.hanfengzhiyi.com/
          file.status = 'done';
          if (type === 'other_material') {
            let item = {
              'file_path': `${res.key}`,
              'file_url': `https://qiniu.hanfengzhiyi.com/${res.key}`,
              'file_name': putExtra.fname
            }
            _this.form[type].push(item)
          } else {
            var sourceLink = `${res.key}`
            _this.form[type] = sourceLink
            _this.form[`${type}_title`] = putExtra.fname
          }
        }
      };
      observable.subscribe(observer);
    },
  },
};
</script>

<style scoped  lang="scss">
.resume-page {
  padding-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.work-page {
  text-align: left;
  padding-bottom: 65px;
}

.work-page .title {
  padding: 10px 20px;
  margin: 10px 0 0 0;
}

.submit-content {
  margin-top: 20px;
  display: flex;
  padding: 0 10px;
}

.submit-btn {
  flex: 1;
  height: 44px;
  background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
  border-radius: 22px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border-color: #e95944;
}

.submit-btn.disabled span {
  opacity: 0.5;
}

.submit-btn-cancel span {
  display: block;
  text-align: center;
  line-height: 44px;
}

.field-focus::after {
  border-color: #e95944;
  transform: scaleY(1);
}

.experience-model-card {
  padding: 10px;

  .experience-model-header {

    display: flex;
    align-items: center;

    p {
      font-weight: bold;
      margin-right: 15px;
    }
  }
}

::v-deep {
  .submit-btn.van-button--disabled {
    opacity: 1;
  }

  .submit-btn.van-button--disabled span {
    opacity: 0.5;
  }

  .van-cell {
    display: block;
    line-height: 28px;
    padding: 13px 20px;
    font-size: 16px;
    color: #333;
    border-color: #f1f4f6;
  }

  .van-cell__title {
    font-size: 12px;
    color: #666;
    padding-bottom: 5px;
  }

  .field-arrow .van-cell__value {
    position: relative;
    padding-right: 20px;
  }

  .field-arrow .van-cell__value::after {
    display: block;
    content: "";
    width: 19px;
    height: 16px;
    background: url(../../assets/images/mine/icon-arrow.png);
    background-size: 19px 16px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 8px 0;
  }

  .van-picker__confirm {
    color: #e95944;
  }

  .van-field__label {
    width: 28em;
  }

  .photo {

    .van-uploader__upload,
    .van-uploader__preview-image {
      width: 74px;
      height: 103px;
    }
  }

  .van-step--horizontal {
    text-align: left;
  }

  .van-step__icon--active,
  .van-step__icon--finish,
  .van-step__title--active,
  .van-step__title--finish {
    color: #e95944;
  }

  .van-step--finish .van-step__circle,
  .van-step--finish .van-step__line {
    background: #e95944;
  }

  .experience-table {
    .header {
      font-size: 13px;
      font-weight: bold;
      border: 1px solid #ebedf0;

      .van-col {
        border-right: 1px solid #ebedf0;
        padding: 5px 0;

        &:last-child {
          border: none;
        }
      }

      span {
        display: block;
        color: #e95944;
        font-size: 12px;
      }
    }

    .list {
      font-size: 13px;
      border: 1px solid #ebedf0;
      border-top: none;

      .van-col {
        border-right: 1px solid #ebedf0;

        &:last-child {
          border: none;
        }
      }
    }
  }
}

.form-tips {
  text-align: left;
  font-weight: bold;
  padding-left: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}


.vant-select {
  width: 100%;
  height: 35px;
  line-height: 35px;
}

::v-deep{
  .van-field--error .el-input__inner::placeholder{
    color:#ee0a24;
  }
}

@media (max-width: 768px) {
  ::v-deep {
    .van-col--12 {
      width: 100%;
    }

    .edu-experience-table {

      // .van-col--6,
      // .van-col--4,
      // .van-col--3,
      // .van-col--2 {
      //   width: 100%;
      // }
    }
  }
}

@media (min-width: 992px) {
  .form-tips {
    text-align: left;
    font-weight: bold;
    padding-left: 15px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .resume-page {
    max-width: 900px;
    margin: 0 auto;
  }

  .submit-btn {
    max-width: 200px;
    margin: 0 auto;
  }

  .experience-model-card {
    padding: 10px;

    .experience-model-header {

      display: flex;
      align-items: center;

      p {
        font-weight: bold;
        margin-right: 15px;
      }
    }
  }

  .vant-select {
    width: 100%;
    height: 35px;
    line-height: 35px;
    border-color: #cdcdcd;
  }

  ::v-deep {
    .photo {

      .van-uploader__upload,
      .van-uploader__preview-image {
        width: 148px !important;
        height: 206px !important;
      }
    }

    .upload-image {

      .van-uploader__upload,
      .van-uploader__preview-image {
        width: 200px !important;
        min-height: 100px !important;
        height: auto !important;
      }
    }

    .upload-video{
      .van-uploader__preview .van-uploader__file{
        width: 200px !important;
        min-height: 200px !important;
      }
    }

    .uploader-file {

      .van-uploader__file,
      .van-uploader__preview {
        width: 600px !important;
        height: 80px !important;
      }
    }

    .van-field__label {
      width: 40em !important;
    }

    .van-step {
      font-size: 14px;
    }

    .van-step--horizontal .van-step__title {
      font-size: 14px;
    }

    .van-steps--horizontal .van-steps__items {
      margin: 0 0 40px;
      padding-bottom: 25px;
    }

    .van-step--horizontal .van-step__circle-container {
      top: 40px;
    }

    .van-step--horizontal .van-step__line {
      top: 40px;
    }

    .van-step--horizontal .van-step__icon {
      font-size: 32px;
    }

    .experience-table {
      .header {
        font-size: 13px;
        font-weight: bold;
        border: 1px solid #ebedf0;

        .van-col {
          border-right: 1px solid #ebedf0;
          padding: 5px 0;

          &:last-child {
            border: none;
          }
        }

        span {
          display: block;
          color: #e95944;
          font-size: 12px;
        }
      }

      .list {
        font-size: 13px;
        border: 1px solid #ebedf0;
        border-top: none;

        .van-col {
          border-right: 1px solid #ebedf0;

          &:last-child {
            border: none;
          }
        }
      }
    }


    // .el-input{
    //   font-size: 14px;

    //   .el-input__inner{
    //     padding:0 10px;
    //     height: 35px;
    //     line-height: 35px;
    //     border-radius: 5px;
    //   }
    // }

    .van-field--error+.van-field-self {
      .el-input .el-input__inner::placeholder {
        color: #ee0a24;
      }
    }

  }
}
</style>
